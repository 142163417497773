const INIT_STATE = {
    selectedService: {},
    serviceList: [],
    selectedCorporate: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SERVICE_LIST": {
            return {
                ...state,
                serviceList: action.payload
            };
        }

        case "SELECTED_SERVICE": {
            return {
                ...state,
                selectedService: action.payload
            };
        }
        case "SELECTED_CORPORATE": {
            return {
                ...state,
                selectedCorporate: action.payload
            };
        }

        default:
            return state;

    }
}
