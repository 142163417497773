import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import FeatherIcon from "feather-icons-react";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import FormHandler from "../shared/utils/FormHandler";
import useBlogApi from "../apis/blogApi";
import { validateBlogUpdate } from "../utils/FormValidationRules";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
        
const Blogs = () => {
  const [isModelVisible, setIsModalVisible] = useState(false);
  const { blogs, editBlog, addBlog, deleteBlog, publishBlog } = useBlogApi();
  const [isUpdate, setIsUpdate] = useState(false);
  const { values, errors, handleChange, handleOnBlur, setValue, initForm } =
    FormHandler(editBlog, validateBlogUpdate);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [socialmediaPost, setSocialmediaPost] = useState([]);
  const [clickedBlog, setClickedBlog] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [publishDate, setPublishDate] = useState("");

  const sortedBlogs = [...blogs].sort(
    (a, b) =>
      new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
  );

  useEffect(() => {
    values.visible = isChecked;
  }, [isChecked]);

  function resetFormData() {
    setIsModalVisible(false);
    initForm({});
    Object.keys(errors).forEach((key) => delete errors[key]);
    setRelatedPosts([]);
    setSocialmediaPost([]);
  }

  const handleAddRelatedPost = () => {
    setRelatedPosts([
      ...relatedPosts,
      { article_id: "", title: "", subtitle: "", image: "", publishedAt: "" },
    ]);
  };

  const handleAddSocialmediaPost = () => {
    setSocialmediaPost([
      ...socialmediaPost,
      { option: "", type: "", title: "", description: "", image: "", link: "" },
    ]);
  };

  const handleRelatedPostChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPosts = [...relatedPosts];
    updatedPosts[index][name] = value;
    setRelatedPosts(updatedPosts);
  };

  const handleSocialmediaPostChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPosts = [...socialmediaPost];
    updatedPosts[index][name] = value;
    setSocialmediaPost(updatedPosts);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (isUpdate) {
      const formErrors = validateBlogUpdate(values);
      if (Object.keys(formErrors).length > 0) {
        Object.keys(formErrors).forEach(
          (key) => (errors[key] = formErrors[key])
        );
        return;
      }
    }

    const nonEmptyRelatedPosts = relatedPosts.filter(
      (post) =>
        post.article_id.trim() !== "" ||
        post.title.trim() !== "" ||
        post.subtitle.trim() !== "" ||
        post.image.trim() !== "" ||
        post.publishedAt.trim() !== ""
    );

    const nonEmptySocialPosts = socialmediaPost.filter(
      (post) =>
        post.option.trim() !== "" ||
        post.type.trim() !== "" ||
        post.title.trim() !== "" ||
        post.description.trim() !== "" ||
        post.image.trim() !== "" ||
        post.link.trim() !== ""
    );

    const updatedValues = {
      ...values,
      related_posts: nonEmptyRelatedPosts,
      social_media_links: nonEmptySocialPosts,
    };

    if (isUpdate) {
      editBlog(updatedValues);
    } else {
      addBlog(updatedValues);
    }
    resetFormData();
  };

  const handleEditClick = (blog) => {
    setIsUpdate(true);
    setSelectedOptions(values.technology);

    Object.keys(errors).forEach((k) => (errors[k] = ""));

    values.article_id = blog.attributes.article_id;
    values.id = blog._id;
    values.title = blog.attributes.title;
    values.subtitle = blog.attributes.subtitle;
    values.language = blog.attributes.language;
    values.visible = blog.attributes.visible;
    values.type = blog.attributes.type;
    values.image = blog.attributes.image;
    values.product = blog.attributes.product;
    values.industry = blog.attributes.industry;
    values.technology = (blog.attributes.technology ? blog.attributes.technology : []);
    setSelectedOptions(values.technology);
    values.published_date = blog.attributes.published_date ? blog.attributes.published_date : "";
    setPublishDate(values.published_date);
    setRelatedPosts(blog.attributes.related_posts || []);
    setSocialmediaPost(blog.attributes.social_media_links || []);
    setIsChecked(blog.attributes.visible);
    setIsModalVisible(true);
  };

  const handleDeleteClick = (blog_id) => {
    deleteBlog(blog_id);
    handleModal(false);
  };

  // Close and open the modal
  const handleModal = (visible, blog_id) => {
    setIsVisible(visible);
    if (visible) {
      setClickedBlog(blog_id);
    } else {
      setClickedBlog(null);
    }
  };

  const handlePublishClick = (article_id, published) => {
    if (published) {
      return;
    }
    publishBlog(article_id);
  };

  const handleDateChange = (event) => {
    values.published_date = event.target.value;
    setPublishDate(values.published_date);
  };

  const technology = [
  "ML" ,
  "Bot Service" ,
  "Auto ML" ,
  "Big Data Analytics" ,
  "IoT" ,
  "Edge Computing" ,
  "Software" ,
  "Computer Vision" 
];

  return (
    <div>
      <Container>
        <button
          onClick={() => {
            setIsModalVisible(true);
            setIsUpdate(false);
            initForm({});
          }}
          className="sa-table-btn-secondary sa-table-float-right"
        >
          <FeatherIcon icon={"plus"} className={"sa-table-icon-size"} />{" "}
          <span className={"sa-table-icon"}>ADD</span>
        </button>
        <div className="sa-table-container ccr-user-calc-height">
          <table className="table table-borderless sa-table-width">
            <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{"Article Id"}</th>
                <th className={"sa-table-head-sticky"}>{"Title"}</th>
                <th className={"sa-table-head-sticky"}>{"Sub Title"}</th>
                <th className={"sa-table-head-sticky"}>{"Language"}</th>
                <th className="sa-table-item-align sa-table-head-sticky"></th>
              </tr>
            </thead>
            <tbody>
              {sortedBlogs.map((blog, index) => (
                <tr key={index}>
                  <td className={"sa-table-data"}>
                    {blog.attributes.article_id}
                  </td>
                  <td className={"sa-table-data"}>{blog.attributes.title}</td>
                  <td className={"sa-table-data"}>
                    {blog.attributes.subtitle}
                  </td>
                  <td className={"sa-table-data"}>
                    {blog.attributes.language}
                  </td>
                  <td className="sa-table-item-align">
                    <div className={"sa-table-action-flex"}>
                      <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title="Edit"
                      >
                        <FeatherIcon
                          onClick={() => handleEditClick(blog)}
                          className="table-action"
                          icon={"edit"}
                        />
                      </div>
                      <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <FeatherIcon
                          onClick={() => handleModal(true, blog._id)}
                          className="text-red"
                          icon={"trash-2"}
                        />
                      </div>
                      <div
                        className="sa-table-actions sa-action-m"
                        data-toggle="tooltip"
                        title={
                          blog.attributes.published ? "Published" : "Publish"
                        }
                      >
                        <FeatherIcon //button
                          onClick={() =>
                            handlePublishClick(
                              blog.attributes.article_id,
                              blog.attributes.published
                            )
                          }
                          className={
                            blog.attributes.published
                              ? "table-action text-success"
                              : "table-action"
                          }
                          icon={
                            blog.attributes.published
                              ? "check-circle"
                              : "arrow-right-circle"
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>

      <div className={"sa-popup-bg " + (!isModelVisible && "hide")}>
        <div className="sa-popup">
          <form onSubmit={handleFormSubmit} className={"sa-modal-box-style"}>
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>
                {!isUpdate ? "ADD BLOG" : "EDIT BLOG"}
              </span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"} />
              </div>
            </div>
            <div className="sa-popup-content">
              {!isUpdate && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="article_id">Article Id</label>
                    <input
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.article_id || ""}
                      className={`form-control`}
                      name="article_id"
                    />
                  </div>
                </div>
              )}
              {isUpdate && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.title || ""}
                      className={`form-control`}
                      name="title"
                    />
                    {errors.title && (
                      <p className="warning-input-msg">{errors.title}</p>
                    )}
                  </div>
                </div>
              )}
              {isUpdate && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="subTitle">Sub Title</label>
                    <textarea
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.subtitle || ""}
                      className="form-control"
                      name="subtitle"
                    />
                    {errors.subtitle && (
                      <p className="warning-input-msg">{errors.subtitle}</p>
                    )}
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="language">Language</label>
                  <select
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.language || ""}
                    className={`form-control`}
                    name="language"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="ENG">ENG</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="type">Type</label>
                  <select
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.type || ""}
                    className={`form-control`}
                    name="type"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="BLOGS">BLOGS</option>
                    <option value="CASE_STUDIES">CASE STUDIES</option>
                    <option value="TECH_BLOGS">TECH BLOGS</option>
                    <option value="SUCCESS_STORIES">SUCCESS STORIES</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="type">Published Date</label>
                  <div>
                  <input
                    type="date"
                    id="publishedDate"
                    value={publishDate}
                    onChange={handleDateChange}
                    className={`form-control`}
                  />
                  </div>
                </div>
              </div>

              {isUpdate && (
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked(!isChecked);
                      }}
                      name="visible"
                      className="mr-2"
                    />

                    <label htmlFor="visible">Visible</label>
                  </div>
                </div>
              )}
            </div>

            {/* category */}

            <div className="col-md-6 activityAddField">
              <div className="form-group m-b-16">
                <span className={"sa-table-icon-flex rbt-input-multi"}>
                  <label className={"labelSize"}>{`Define Article Category`}</label>
                </span>
              </div>
            </div>

            <div className="sa-popup-content">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="product">Product</label>
                      <select
                        onBlur={handleOnBlur}
                        onChange={handleChange}
                        value={values.product || ""}
                        className={`form-control`}
                        name="product"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="senzagro">SenzAgro</option>
                        <option value="senzmatica">SenzMatica</option>
                        <option value="devicepulse">Devicepulse</option>
                        <option value="polar">Polar</option>
                        <option value="senzcare">SenzCare</option>
                      </select>
                    </div>
                  </div>
                
                <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="industry">Industry</label>
                      <select
                        onBlur={handleOnBlur}
                        onChange={handleChange}
                        value={values.industry || ""}
                        className={`form-control`}
                        name="industry"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="medtech">MedTech</option>
                        <option value="finance">Finance</option>
                        <option value="tourism">Tourism</option>
                        <option value="hospitality">Hospitality</option>
                        <option value="sports">Sports</option>
                        <option value="agriculture">Agriculture</option>
                        <option value="retail">Retail</option>
                      </select>
                    </div>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="technology">Technology</label>
                    <div>

                    <Select
                      multiple
                      value={selectedOptions}
                      onChange={(e) => {
                        setSelectedOptions(e.target.value);
                        values.technology = e.target.value;
                      }}
                      input={<OutlinedInput label="" />}
                      className={`form-control`}
                      style={{ fontSize: "14px", height: "44px", fontFamily: "Poppins" }}
                    >
                      {technology.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={{ fontSize: "14px", height: "44px", fontFamily: "Poppins", width: '100%' }}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    </div>
                  </div>
                </div>

              </div>

            <div className="col-md-6 activityAddField">
              <div className="form-group m-b-16">
                <span className={"sa-table-icon-flex rbt-input-multi"}>
                  <label className={"labelSize"}>{`Related Posts`}</label>
                  <button
                    className="btn btn-sa-secondary pis-add-activityFields"
                    type={"button"}
                    onClick={handleAddRelatedPost}
                  >
                    <FeatherIcon className={"icon-in-btn"} icon={"plus"} />{" "}
                    <span className={"hide-375"}></span>
                  </button>
                </span>
              </div>
            </div>
            <div className="sa-popup-content">
              {relatedPosts.map((post, index) => (
                <div
                  key={index}
                  className="w-100 d-flex flex-wrap bg-gray data-box"
                >
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`article_id-${index}`}>Article Id</label>
                      <input
                        type="text"
                        className="form-control"
                        name="article_id"
                        value={post.article_id}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`title-${index}`}>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={post.title}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`subtitle-${index}`}>Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="subtitle"
                        value={post.subtitle}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`image-${index}`}>Image</label>
                      <input
                        type="text"
                        className="form-control"
                        name="image"
                        value={post.image}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="type">Social Media</label>
                      <select
                        onBlur={handleOnBlur}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                        value={post.socialmedia}
                        className={`form-control`}
                        name="socialmedia"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="twitter">Twitter</option>
                        <option value="tiktok">TikTok</option>
                        <option value="youtube">YouTube</option>
                        <option value="facebook">Facebook</option>
                        <option value="linkedin">LinkedIn</option>
                        <option value="other">other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`publishedAt-${index}`}>
                        Published At
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="publishedAt"
                        value={post.publishedAt}
                        onChange={(e) => handleRelatedPostChange(index, e)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-md-6 activityAddField">
              <div className="form-group m-b-16">
                <span className={"sa-table-icon-flex rbt-input-multi"}>
                  <label
                    className={"labelSize"}
                  >{`Related Social Media`}</label>
                  <button
                    className="btn btn-sa-secondary pis-add-activityFields"
                    type={"button"}
                    onClick={handleAddSocialmediaPost}
                  >
                    <FeatherIcon className={"icon-in-btn"} icon={"plus"} />{" "}
                    <span className={"hide-375"}></span>
                  </button>
                </span>
              </div>
            </div>
            <div className="sa-popup-content">
              {socialmediaPost.map((post, index) => (
                <div
                  key={index}
                  className="w-100 d-flex flex-wrap bg-gray data-box"
                >
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`option-${index}`}>Option</label>
                      <select
                        onBlur={handleOnBlur}
                        onChange={(e) => handleSocialmediaPostChange(index, e)}
                        value={post.option}
                        className={`form-control`}
                        name="option"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="socialMedia">Social Media</option>
                        <option value="weblink">Web Link</option>
                      </select>
                    </div>
                  </div>
                  {post.option === "socialMedia" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor={`type-${index}`}>Type</label>
                        <select
                          onBlur={handleOnBlur}
                          onChange={(e) =>
                            handleSocialmediaPostChange(index, e)
                          }
                          value={post.type}
                          className="form-control"
                          name="type"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="twitter">Twitter</option>
                          <option value="tiktok">TikTok</option>
                          <option value="youtube">YouTube</option>
                          <option value="facebook">Facebook</option>
                          <option value="linkedin">LinkedIn</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`title-${index}`}>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={post.title}
                        onChange={(e) => handleSocialmediaPostChange(index, e)}
                      />
                    </div>
                  </div>
                  {post.option === "weblink" && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor={`image-${index}`}>Image</label>
                        <input
                          type="text"
                          className="form-control"
                          name="image"
                          value={post.image}
                          onChange={(e) =>
                            handleSocialmediaPostChange(index, e)
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`description-${index}`}>
                        Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        value={post.description || ""}
                        onChange={(e) => handleSocialmediaPostChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor={`link-${index}`}>Link</label>
                      <input
                        type="text"
                        className="form-control"
                        name="link"
                        value={post.link}
                        onChange={(e) => handleSocialmediaPostChange(index, e)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="sa-popup-btn">
              <button
                type={"button"}
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                onClick={resetFormData}
              >
                CANCEL
              </button>
              <button type="submit" className="sa-popup-secondary-btn-style">
                {!isUpdate ? "ADD" : "UPDATE"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* added code */}
      <div className={"sa-popup-bg " + (!isVisible && "hide")}>
        <div className="sa-popup">
          <form className="sa-modal-box-style">
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>DELETE BLOG</span>
              <div
                className="sa-popup-close-icon"
                onClick={() => handleModal(false)}
              >
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"} />
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-12">
                <div className="form-group">
                  <p>Are you sure you want to delete this blog?</p>
                </div>
              </div>
            </div>
            <div className="sa-popup-btn">
              <button
                type="button"
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                onClick={() => handleModal(false)}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="sa-popup-secondary-btn-style sa-popup-danger-btn-style"
                onClick={() => {
                  handleDeleteClick(clickedBlog);
                }}
              >
                DELETE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
