import 'react-bootstrap-typeahead/css/Typeahead.css';
import './App.css';
import Login from "./shared/components/login";
import React from "react";
import SignUp from "./shared/components/sign-up";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Loader from "./shared/components/loader";
import Reset from "./shared/components/reset";
import {getAccessToken} from "./shared/utils/SharedAuthentication";
import MainLayout from "./layouts/main-layout";
import ChangePassword from "./shared/components/change-password";
import ForgotPassword from "./shared/components/forgot-password";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import axios from "axios";

function App() {
    return (
        <Router>
            <div className="App">
                <ChangePassword/>
                <Loader/>
                <ToastContainer position="bottom-right"/>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/forgot-password" component={ForgotPassword}/>
                    <Route path="/reset" component={Reset}/>
                    <Route path="/sign-up" component={SignUp}/>
                    <PrivateRoute path="/*" component={MainLayout}/>
                </Switch>
            </div>
        </Router>
    );
}
axios.interceptors.response.use(
    async (response) => {
        return response
    },
    async (error) => {
        const originalRequest = error.config
        const serverCallUrl = new URL(originalRequest.url)
        const status = error.response && error.response.status



        if ((status === 401) && !originalRequest._retry && !serverCallUrl.pathname.includes('/oauth')) {
            // let token = await refreshAccessToken()
            // setAccessToken(token)

            // originalRequest._retry = true
            // originalRequest.headers.authorization = `Bearer ${token}`

            // return axios(originalRequest)
            localStorage.clear();
            window.location.href = "/login";

            return;
        }

        return Promise.reject(error)
    })

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            getAccessToken() ?
                <Component {...props} />
                :
                (
                    <Redirect
                        to={{
                            pathname: "/login",
                            query: props.location
                        }}
                    />
                )
        }
    />

);


export default App;
