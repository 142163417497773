import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE} from "../utils/Enum";
import {changeToggle} from "../shared/actions/setting";


function ToggleButton(props) {

    const [hideNavigation, setHideNavigation] = useState(false);
    const toggle = useSelector(state => {
        return state.setting.toggle
    });
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();



    useEffect(() => {
        let paths = ["/", "/home", "/blogs"];
        if (paths.includes(location.pathname)) {
            setHideNavigation(true);
        }else {
            setHideNavigation(false);
        }
    }, [location]);

    const selectedService = useSelector(state => {
        return state.serviceList.selectedService;
    });

    console.log(location.pathname)

    return (
        <div>
            <div className="container container-toggle-btn mb-5" hidden={hideNavigation}>
                <div className={"sa-bread-crumb"}>
                    <div className={"d-flex"}>
                        <div className={"sa-bc-home sa-cursor"} onClick={() => {
                            history.push("/")
                        }}><FeatherIcon className={"icon-in-btn"} icon="home"/></div>

                        <div
                            className={"sa-bc-farm " + (location.pathname !== "/services" ? "sa-cursor" : "") + (false ? "sa-bc-right-border" : "")}
                            onClick={() => {
                                if(location.pathname === "/services"){
                                    return
                                }
                                history.push("/services")
                            }}
                            hidden={false}>
                            <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
                            <FeatherIcon className={"icon-in-btn"} icon="user-check"/>
                            <span className={"hide-768"}> {"Manage Services"}</span>
                        </div>
                        <div className={"sa-bc-plot"} onClick={() => {
                        }} hidden={location.pathname.length < 10}>
                            <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
                            <FeatherIcon className={"icon-in-btn"} icon="pie-chart"/>
                            {selectedService.name}
                        </div>

                       
                    </div>
                </div>


                <div className="pull-right toggle-btn" hidden={location.pathname !=="/services"}>
                    <button id="toggle-list"
                            className={"list btn first-tog first-tog-size " + (toggle===TOGGLE.LIST ? 'p-l-11 height-44 tog-sa-primary hide-768 ' : 'tog-sa-secondary ')}
                            onClick={() => {
                                dispatch(changeToggle(TOGGLE.LIST))
                            }}>
                        <FeatherIcon className={"icon-in-btn"} icon="list"/> <span className={"hide-768"}> LIST</span>
                    </button>

                    <button id="toggle-map"
                            className={"map btn second-tog second-tog-map " + (toggle===TOGGLE.CARD ? 'tog-sa-primary hide-768' : 'tog-sa-secondary')}
                            onClick={() => {

                                dispatch(changeToggle(TOGGLE.CARD))
                            }}>
                        <FeatherIcon className={"icon-in-btn"} icon="grid"/> <span className={"hide-768"}> CARD</span>
                    </button>

    
                </div>
            </div>
        </div>
    );
}

export default ToggleButton;
