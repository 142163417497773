export function validateAddServices(values) {
    let errors = {};

    if (!values.name) {
        errors.name = ' Name is required';
    }
    if (!values.url) {
        errors.url = 'URL is required';
    }
    if (!values.description) {
        errors.description = ' Description is required';
    }
    return errors;
}

export function validateBlogUpdate(values) {
    let errors = {};

    if (!values.title) {
        errors.title = 'Title is required';
    }
    if (!values.subtitle) {
        errors.subtitle = 'Sub title is required';
    }
    return errors;
}
