import React, {useEffect, useState} from 'react';
import NavbarLayout from "../shared/components/navbarLayout";
import {useHistory, useLocation} from "react-router-dom";
import {getName, getRefCount, getRole, getUserId, signOut} from "../shared/utils/SharedAuthentication";
import {useDispatch, useSelector} from "react-redux";
import {toggleChangePassword, toggleLoader} from "../shared/actions/setting";
import packageJson from "../../package.json";
import axios from "axios";
import {getSelectedCorporate} from "../actions/service";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';


export default function Navbar(props) {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [corporateData, setCorporateData] = useState([]);

    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    };
  

    function text() {
        return<div>He</div>
    }

    function expandPageNotification(){
        history.push("/notification-page");
    }

    function brand() {
        return <span onClick={()=> history.push("/")} className={"nav-logo"}>SENZWEB ADMIN</span>
    }
    function about() {
        window.open("https://senzagro.com", "_blank");
    }
    function showChangePassword() {
        dispatch(toggleChangePassword(true));

    }

    function logOut() {
        signOut();
        history.push("/login");
    }

    function onPaths(paths) {
        return (match, location) => {
            if (paths === "SF") {
                return (
                    !location.pathname.match("/daily-update") &&
                    !location.pathname.match("/cash-flow") &&
                    !location.pathname.match("/settings") &&
                    !location.pathname.match("/xindicate") &&
                    !location.pathname.match("/map-data") &&
                    !location.pathname.match("/corporate") &&
                    !location.pathname.match("/system-config") &&
                    !location.pathname.match("/dashboard") &&
                    !location.pathname.match("/cco") &&
                    !location.pathname.match("/notification-page")
                );
            }
            return location.pathname.match(paths);
        };
    }

    let navItems=[
        { title:"Menu 1",
            isActive:onPaths("/home"),
            to:"/home",
            permissions:true,
            webScreen:true,
            icon:"map",
        },{ title:"Menu 2",
            isActive:onPaths("/services"),
            to:"/services",
            permissions:true,
            webScreen:true,
            icon:"map",
        },{ title:"Blogs",
            isActive:onPaths("/blogs"),
            to:"/blogs",
            permissions:true,
            webScreen:true,
            icon:"map",
        }
    ]

    let mobileNavbar=[
        {
            title:"Magma v"  +packageJson.version,
            click:about,
            icon:"alert-circle"
        }, {
            title:"Change Password",
            click:showChangePassword,
            icon:"lock"
        }, {
            title:"Logout",
            click:logOut,
            icon:"log-out",
            logout:true
        }
    ]
    let txts={
        language:"Select Language",
        notifications:"Notifications",
        emptyNotifications:"There are no notifications",
        corporate:"Select Corporate",
    }
    let languages=[
        {
          title:t("title.ENGLISH"),
          lng:"en",
          icon:"E",
        },{
          title:t("title.TAMIL"),
          lng:"ta",
          icon:"T",
        },{
          title:t("title.SINHALA"),
          lng:"si",
          icon:"S",
        },{
          title:"JAPANESE",
          lng:"ja",
          icon:"J",
        }
    ]

    const selectedCorporate = useSelector((state) => {
        return state.serviceList.selectedCorporate;
    });

    console.log(selectedCorporate)

    useEffect(()=>{
        dispatch(toggleLoader(true))
        axios.get(process.env.REACT_APP_HOST + `user/` + getUserId() + `/corporate`  )
            .then(function (response) {
                setCorporateData(response.data.content);
                if (response.data.content.length > 0) {
                    dispatch(getSelectedCorporate(response.data.content[0].id));
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 422) {
                    toast.error(error.response.data.message);
                }
            }).finally(() => {
            dispatch(toggleLoader(false))
        });
    },[])



    return (
        <div>
            <NavbarLayout notificationDot={false} txts={txts} settingPermission={false}
                          notificationCard={text}
                          corporateEnable={true}
                          corporateChange={(id)=> dispatch(getSelectedCorporate(id))}
                          corporateList={corporateData}
                          selectedCorporate={selectedCorporate}
                          notificationActive={false}
                          expandPageNotification={expandPageNotification}
                          name={getName()} settingList={[]}languages={languages} changeLanguage={changeLanguage}navItems={navItems}
                          mobileNavbar={mobileNavbar} loadNotifications={[]} role={getRole()} refs={getRefCount()}
                          brand={() => brand()}/>
        </div>
    );
}

