import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE} from "../utils/Enum";
import {changeToggle} from "../shared/actions/setting";




function Home(props) {

    return (
        <div>
            Home
        </div>
    );
}

export default Home;
