import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../src/shared/actions/setting";

const useBlogApi = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  // Fetch all blogs
  const fetchBlogs = () => {
    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_HOST + `/articles`)
      .then((res) => {
        setBlogs(res.data);
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        toast.error("Failed to fetch blogs.");
        dispatch(toggleLoader(false));
      });
  };

  const editBlog = (blogData) => {
    dispatch(toggleLoader(true));
    axios
      .patch(process.env.REACT_APP_HOST + `/article/edit`, blogData)
      .then((res) => {
        toast.success("Blog updated successfully!");
        fetchBlogs();
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        toast.error("Failed to update blog.");
        dispatch(toggleLoader(false));
      });
  };

  const addBlog = (blogData) => {
    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_HOST + `/article/add`, {
        ...blogData,
        visible: false,
      })
      .then((res) => {
        toast.success("Blog added successfully!");
        fetchBlogs();
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        toast.error("Failed to add blog.");
        dispatch(toggleLoader(false));
      });
  };

  const deleteBlog = (id) => {
    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_HOST + `/delete`, { id })
      .then((res) => {
        toast.success("Blog deleted successfully!");
        fetchBlogs();
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to delete blogs.");
        dispatch(toggleLoader(false));
      });
  };

  const publishBlog = (article_id) => {
    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_HOST + `/publish-article`, { article_id })
      .then((res) => {
        toast.success("Blog published successfully!");
        fetchBlogs();
        dispatch(toggleLoader(false));
      })
      .catch((error) => {
        toast.error("Failed to publish blog.");
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return {
    blogs,
    editBlog,
    addBlog,
    deleteBlog,
    publishBlog,
  };
};

export default useBlogApi;
